import { Badge, Card, Col, Stack } from 'react-bootstrap'
import { PdfDisplayer } from '../../common/PdfComponents'

function ResultsCard({ event }) {
  const result = event?.userTicket?.result
  const personSnapshot = event?.userTicket?.personSnapshot
  if (!result) {
    return <div className="text-center fw-bold p-3">You have no result yet</div>
  }

  console.log(result)

  return (
    <Col xs={12} lg={12} className="mb-3">
      <Card style={{ boxShadow: '10px 10px' }} className="border-black h-100">
        <Card.Header className="mb-0 pb-0">
          <h4 className="fw-bold">{event.eventName} Result</h4>
          <h5>
            {personSnapshot.island + ' | '}
            {personSnapshot.yearLevel < 11 ? 'Junior' : 'Senior'} Division
          </h5>
          <p className="lead">#{event.eventCode}</p>
        </Card.Header>
        <Card.Body>
          <p className="lead fw-bold text-center mb-0">
            ✨ Score: {result.score}
          </p>
          <Stack
            direction="horizontal"
            gap={1}
            style={{ flexWrap: 'wrap', justifyContent: 'center' }}
          >
            {result.titles.map((title) => (
              <Badge
                key={title}
                bg="primary"
                className="mt-3"
              >{`🏅 ${title}`}</Badge>
            ))}
          </Stack>
          <PdfDisplayer pdfUrls={result.attachmentURLs} className="mt-3" />
        </Card.Body>
      </Card>
    </Col>
  )
}

export default ResultsCard
